import Choices from 'choices.js';

const selectElements = document.querySelectorAll('select');

if (selectElements.length > 0) {
    selectElements.forEach((select) => {
        new Choices(select, {
            searchEnabled: false,
            itemSelectText: '',
        });
    });
}
