import { throttle } from 'lodash';

const adaptiveCursorItems = document.querySelectorAll('[data-adaptive-cursor]');
if (adaptiveCursorItems.length) {
  adaptiveCursorItems.forEach((item) => {
    const span = document.createElement('span');
    span.classList.add('adaptive-cursor');
    if (item.dataset.adaptiveCursor === 'invert')
      span.classList.add('adaptive-cursor--invert');

    item.appendChild(span);

    item.addEventListener(
      'mousemove',
      throttle(({ layerX, layerY, target: { offsetWidth } }) => {
        span.style.left = layerX + 'px';
        span.style.top = layerY + 'px';

        let pxBetweenEdges = 0;

        if (layerX > offsetWidth / 2) {
          pxBetweenEdges = offsetWidth - layerX;
        } else {
          pxBetweenEdges = layerX;
        }

        const scaleSize =
          pxBetweenEdges > 20
            ? (pxBetweenEdges * 100) / (offsetWidth / 2) / 40
            : 0.8; // For cursor min size

        span.style.transform = `translate(-50%, -50%) scale(${scaleSize})`;
      }, 100)
    );
  });
}
