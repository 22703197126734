window.addEventListener('load', () => {
  const slideAnimationsArr = document.querySelectorAll('.slide-animation');
  if (slideAnimationsArr.length) {
    for (let i = 0; i < slideAnimationsArr.length; i++) {
      const results = Splitting({
        target: slideAnimationsArr[i],
      });

      // results[0].words.forEach((word) => {
      //     word.innerHTML = `<span class='inner'>${word.innerText}</span>`;
      // });

      setTimeout(() => slideAnimationsArr[i].classList.add('show'), 100);
    }
  }

  const slideAnimationsByWordsArr = document.querySelectorAll(
    '.slide-animation-words'
  );
  if (slideAnimationsByWordsArr.length) {
    slideAnimationsByWordsArr.forEach((item) => {
      const results = Splitting({
        target: item,
      });
    });
  }

  const slideAnimationsByLinesArr = document.querySelectorAll(
    '.slide-animation-line'
  );
  if (slideAnimationsByLinesArr.length) {
    slideAnimationsByLinesArr.forEach((item) => {
      const results = Splitting({
        target: item,
        by: 'lines',
      });
    });
  }
});

// const target = document.querySelector('.heading');

window.addEventListener('load', () => {
  const projectHero = document.querySelector('.project-hero');

  if (projectHero) {
    const imgWrap = document.querySelector('.hero-image');
    const imgs = imgWrap.querySelectorAll('img');

    const SLIDES_ANIMATION_TIME = 4000;
    let currentImgIdx = 0;

    const observer = new IntersectionObserver(
      (entries) => {
        const isInView = entries[0].isIntersecting;

        if (isInView) {
          imgWrap.classList.add('animated');

          setInterval(() => {
            imgs[currentImgIdx].classList.remove('is-show');

            if (currentImgIdx >= imgs.length - 1) {
              currentImgIdx = 0;
            } else {
              currentImgIdx++;
            }

            imgs[currentImgIdx].classList.add('is-show');
          }, SLIDES_ANIMATION_TIME);
          observer.unobserve(entries[0].target);
        }
      },
      {
        threshold: 0,
      }
    );
    observer.observe(imgWrap);
  }
});

const evolveCounts = document.querySelector('.hero-screen__counts');

if (evolveCounts) {
  const countList = evolveCounts.querySelectorAll('.count-item');

  countList.forEach((item, idx) => {
    const delay = idx * 200;

    item.style.transitionDelay = delay + 'ms';
  });
}

const testimonialsGrid = document.querySelector('.testimonials-grid');

if (testimonialsGrid) {
  const isTablet = window.matchMedia('(max-width: 1024px)').matches;

  testimonialsGrid.classList.add('animated');

  // testimonials gsap section height fix
  const columns = testimonialsGrid.querySelectorAll('[data-speed]');
  const slowestColumn = [...columns].reduce((acc, item) => {
    if (item.dataset.speed < acc.dataset.speed) acc = item;
    return acc;
  }, columns[0]);
  const highestColumn = [...columns].reduce((acc, item) => {
    if (item.scrollHeight < acc.scrollHeight) acc = item;
    return acc;
  }, columns[0]);

  console.log(slowestColumn);

  if (!isTablet) {
    testimonialsGrid.style.maxHeight =
      Math.max(
        slowestColumn.scrollHeight,
        highestColumn.scrollHeight / highestColumn.dataset.speed
      ) + 'px';
  }
}

const brandsGrid = document.querySelector('.brands-grid');

if (brandsGrid) {
  brandsGrid.style.height = brandsGrid.clientHeight * 0.8 + 'px';
}

// For form animation
function showThanks(id) {
  const form = document.getElementById(id);

  form.classList.add('form-sent');
}
window.showThanks = showThanks;

const infoBlocks = document.querySelectorAll('.info-block');

infoBlocks.forEach((link) => {
  link.addEventListener('click', (e) => {
    e.preventDefault();

    const { id } = link.dataset;

    const block = document.querySelector(id);
    console.dir(block.offsetTop);

    scrollTo({
      top: block.offsetTop - 50,
      behavior: 'smooth',
    });
  });
});
