import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './utils/adaptive-cursor.utils.js';
import './utils/gsap.utils.js';
import './utils/select.utils.js';
import './utils/text.utils.js';

import './libs/countUp.lib.js';

import './components/pageLoad.component.js';
import './components/header.component.js';
import './components/animations.component.js';
import './components/marquee.component.js';
import './components/swiper.component.js';
import './components/accordion.component.js';
import './components/inputs.component.js';
import './components/video-popup.component.js';
import './components/workCards.component.js';

const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  return /iphone|ipad|ipod/.test(userAgent);
};

const isFirefox = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  return /firefox/.test(userAgent);
};

const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

if (isIos()) document.body.classList.add('ios');
if (isFirefox()) document.body.classList.add('firefox');

const infoBlocks = document.querySelectorAll('.info-block');
infoBlocks.forEach(
  (item, idx) => (item.style.zIndex = infoBlocks.length - idx)
);

Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
  get: function () {
    return !!(
      this.currentTime > 0 &&
      !this.paused &&
      !this.ended &&
      this.readyState > 2
    );
  },
});
const videoElement = document.getElementById('video-bg-element');

if (videoElement) {
  $('body').on('click touchstart', function () {
    const videoElement = document.getElementById('video-bg-element');

    if (!videoElement.playing) {
      videoElement.play();
    }
  });

  setTimeout(() => {
    console.log('videoElement.playing', videoElement.playing);
    try {
      if (!videoElement.playing) {
        videoElement.play();
      }
    } catch (e) {
      console.log(e);
      videoElement.classList.add('hidden');
    }
  }, 100);
}

window.addEventListener('load', () => {
  const pageLoader = document.querySelector('.page-loader');

  if (pageLoader) {
    setTimeout(() => {
      pageLoader.classList.remove('is-loading');
    }, 100);
  }
});

if (isSafari()) {
  document.body.classList.add('safari');
}

function setUrlParams(paramName, value = 1) {
  window.history.replaceState(null, null, `?${paramName}=${value}`);
}

window.setUrlParams = setUrlParams;

function removeUrlParams() {
  const url = new URL(window.location.href);

  url.search = '';

  window.history.replaceState({}, document.title, url.href);
}

window.removeUrlParams = removeUrlParams;

window.addEventListener('load', () => {
  const regex = /showreel=1/;
  const url = new URL(window.location.href);

  if (regex.test(url.search)) {
    window.openVideo('hero-video');
  }
});
