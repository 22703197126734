window.openVideo = (id) => {
    const popup = document.getElementById(id);

    if (popup) {
        const closeBtn = popup.querySelector('.close-btn');
        const video = popup.querySelector('video');
        const iframe = popup.querySelector('iframe');

        scrollLock.disablePageScroll();

        popup.classList.add('active');

        if (video) {
            video.play();
        }

        if (iframe) {
            const vimeoPlayer = new Vimeo.Player(iframe);
            vimeoPlayer.play();
        }

        closeBtn.addEventListener('click', () => {
            popup.classList.remove('active');

            if (video) {
                video.pause();
            }

            if (iframe) {
                const iframeSrc = iframe.src;
                iframe.src = iframeSrc;
            }

            scrollLock.enablePageScroll();
        });
    }
};
