const marqueeComponents = document.querySelectorAll('.marquee');
if (marqueeComponents.length > 0) {
  marqueeComponents.forEach((marquee) => {
    const marqueeWrap = marquee.querySelector('.marquee__content');
    const childrenCount = marqueeWrap.childElementCount;

    if (marqueeWrap.classList.contains('auto-speed'))
      marqueeWrap.style.animationDuration = `${childrenCount * 7}s`;

    const marqueeWrapDuplicated = marqueeWrap.cloneNode(true);

    marqueeWrapDuplicated.setAttribute('aria-hidden', true);

    marqueeWrap.insertAdjacentElement('afterend', marqueeWrapDuplicated);

    console.dir(marqueeWrap);
    // window.addEventListener('load', () => {
    //   setTimeout(() => {
    //     marqueeWrapDuplicated.style.width = marqueeWrap.scrollWidth + 'px';
    //   }, 150);
    // });
    // marquee.addEventListener('mouseenter', (e) => {
    //     e.target.classList.add('slowed');
    // });
    //
    // marquee.addEventListener('mouseleave', (e) => {
    //     e.target.classList.remove('slowed');
    // });
  });
}
