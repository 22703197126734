window.autosizeTextarea = function autosizeTextarea() {
  if (document.querySelector('textarea[data-autosize]')) {
    const autosizeTextareaElements = document.querySelectorAll(
      'textarea[data-autosize]'
    );
    autosizeTextareaElements.forEach((textarea) => {
      const startHeight = textarea.offsetHeight;
      textarea.addEventListener('input', autosize);
      function autosize() {
        const el = this;
        const newHeight = el.scrollHeight;
        if (newHeight > startHeight) {
          el.style.minHeight = `${el.scrollHeight}px`;
        } else {
          el.style.minHeight = startHeight;
        }
      }
    });
  }
};
autosizeTextarea();

const initErrorsRemoving = () => {
  const inputs = document.querySelectorAll('input, textarea');

  inputs.forEach((input) => {
    input.addEventListener('focus', () => {
      const fieldWrap = input.closest('.input-item');
      const errorText = fieldWrap.querySelector('.error-text');
      input.classList.remove('error');
      input.style.borderColor = null;

      errorText.remove();
    });

    input.addEventListener('change', () => {
      input.classList.remove('error');
      input.style.borderColor = null;
    });
  });
};

initErrorsRemoving();

window.initErrorsRemoving = initErrorsRemoving;
