const mainTitles = document.querySelectorAll('.width-by-text');
if (mainTitles.length) {
    const setWidth = () => {
        mainTitles.forEach((title) => {
            const text = title.querySelector('span');
            if (text) {
                title.style.width = '100%';
                title.offsetWidth > text.offsetWidth
                    ? (title.style.width = `${text.offsetWidth + 1}px`)
                    : false;
            }
        });
    };
    window.onresize = () => setWidth();
    setWidth();
}
