import { gsap } from 'gsap';

import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Function to check if the device is a mobile device
function isMobileDevice() {
  const device =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  return device || window.innerWidth < 768;
}

function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

const headerComponent = document.getElementsByClassName('header')[0];

function disableScrollTrigger(scrollTrigger, maxWidth, maxHeight) {
  if (window.innerWidth < maxWidth && window.innerHeight < maxHeight) {
    scrollTrigger.disable();
  } else {
    scrollTrigger.enable();
  }
}

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

ScrollTrigger.config({
  ignoreMobileResize: true,
  syncInterval: 999999999,
  normalizeScroll: {
    target: window,
    type: 'wheel,touch',
    debounce: true,
  },
});

const scrollTriggerItems = document.querySelectorAll('[data-scroll]');
if (scrollTriggerItems.length > 0) {
  let scrollAnimation;

  scrollTriggerItems.forEach((item) => {
    const options = {
      scrollTrigger: {
        wrapper: '#smooth-wrapper',
        content: '#smooth-content',
        trigger: item.dataset.scrollTrigger,
        scrub: +item.dataset.scrollDuration || 0,
        start: item.dataset.scrollStart || 'top center',
        end: item.dataset.scrollEnd || 'bottom center',
        ignoreMobileResize: true,
        once: item.dataset.once,
        onLeave: () => headerComponent.classList.remove('show-on-scroll'),
      },
      duration: 10,
    };

    if (item.dataset.scrollFromX || item.dataset.scrollFromY) {
      scrollAnimation = gsap
        .timeline(item, options)
        .from(item, {
          x: item.dataset.scrollFromX || 0,
          y: item.dataset.scrollFromY || 0,
          opacity: item.dataset.opacity || 0,
          ...options,
        })
        .to(item, {
          y: item.dataset.scrollY || 0,
          x: item.dataset.scrollX || 0,
          opacity: 1,
          ...options,
        });
    } else {
      scrollAnimation = gsap.to(item, {
        y: item.dataset.scrollY || 0,
        x: item.dataset.scrollX || 0,
        ...options,
      });
    }
  });
}

const pinedSections = gsap.utils.toArray('[data-scroll-pined]');
if (pinedSections.length > 0) {
  pinedSections.forEach((section, i) => {
    const scrollTrigger = ScrollTrigger.create({
      wrapper: '#smooth-wrapper',
      content: '#smooth-content',

      trigger: section,
      start: () => {
        if (section.dataset.scrollPinedStart) {
          return section.dataset.scrollPinedStart;
        } else {
          return section.offsetHeight < window.innerHeight
            ? 'top top'
            : 'bottom bottom';
        }
      },

      pin: true,
      pinSpacing: false,
      ignoreMobileResize: true,
      invalidateOnRefresh: true,
      pinType: isMobileDevice() ? 'fixed' : 'transform',
      preventOverlaps: true,
      anticipatePin: 1,
      // markers: true,
    });

    disableScrollTrigger(scrollTrigger, 1025, 670);

    window.addEventListener('resize', () => {
      disableScrollTrigger(scrollTrigger, 1025, 670);
    });
  });
}

if (!isMobileDevice()) {
  ScrollSmoother.create({
    smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
    effects: true, // looks for data-speed and data-lag attributes on elements
    smoothTouch: false, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
  });
}

const scrollingText = document.querySelectorAll('.scrolling-text');
let scrollingTextSpeed = 20;
if (window.innerWidth > 1366) {
  scrollingTextSpeed = 15;
}
let scrollingTextTimeline = gsap.timeline();
if (scrollingText.length >= 1) {
  const testimonialsGrid = document.querySelector('.testimonials-grid');
  scrollingText.forEach((text, i) => {
    const val = i % 2 === 0 ? scrollingTextSpeed : -scrollingTextSpeed;

    scrollingTextTimeline.to(text, {
      xPercent: val,
      scrollTrigger: {
        trigger: scrollingText[scrollingText.length - 2],
        start: 'center center',
        end: '+=1000',
        scrub: 0,
      },
    });
  });
}

// For opacity lazyloading
// ScrollTrigger.batch('[data-src]', {
//   interval: 0.5,
//   batchMax: 8,
//   start: 'top bottom-=50px',

//   onEnter: (targets) => {
//     console.log(targets);
//     targets.forEach(
//       (el) => (el.src = el.getAttribute('data-src')),

//       gsap.from(targets, {
//         stagger: 0.5,
//         duration: 0.5,
//         opacity: 0,
//         ease: 'power4.out',
//       })
//     );
//   },
// });
