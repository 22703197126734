const accordion = document.querySelector('.accordion');
if (accordion) {
    const accordionItems = document.querySelectorAll('.accordion__item');

    accordionItems.forEach((item) => {
        item.addEventListener('click', () => {
            const header = item.querySelector('.accordion__header');
            const body = item.querySelector('.accordion__body');

            accordionItems.forEach((acc) => {
                if (acc !== item) {
                    const body = acc.querySelector('.accordion__body');
                    body.style.height = null;
                    acc.classList.remove('active');
                }
            });

            item.classList.toggle('active');

            if (body.style.height) {
                body.style.height = null;
            } else {
                body.style.height = body.scrollHeight + 'px';
            }
        });
    });
}
