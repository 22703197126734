import Swiper, {
  Navigation,
  EffectFade,
  Autoplay,
  EffectCreative,
} from 'swiper';

const contactForm = document.querySelector('.contact-form');
if (contactForm) {
  let flag = false;

  const nextButton = contactForm.querySelector('.next');
  const backButton = contactForm.querySelector('.prev');
  const submitButton = contactForm.querySelector('.submit');

  const swiper = new Swiper('.contact-form__swiper', {
    modules: [Navigation, EffectFade],
    slidesPerView: 1,
    allowTouchMove: false,
    autoHeight: true,
    effect: 'effect',
    fadeEffect: {
      crossFade: true,
    },

    navigation: {
      nextEl: '.contact-form__nav .btn.next',
      prevEl: '.contact-form__nav .btn.prev',
    },

    on: {
      init: function (swiper) {
        disableNextStep(swiper);
      },
      slideNextTransitionStart: function (swiper) {
        disableNextStep(swiper);
      },
      slidePrevTransitionStart: function (swiper) {
        disableNextStep(swiper);
      },
    },
  });

  function disableNextStep(swiper) {
    const slide = swiper.slides[swiper.realIndex];

    const input =
      slide.querySelector('input') || slide.querySelector('textarea');

    if (swiper.realIndex <= 0) {
      backButton.classList.add('hide');
    } else {
      backButton.classList.remove('hide');
    }

    if (swiper.slides.length === swiper.realIndex + 1) {
      nextButton.classList.add('hidden');
      submitButton.classList.remove('hidden');
    } else {
      nextButton.classList.remove('hidden');
      submitButton.classList.add('hidden');
    }

    if (input.value) {
      nextButton.classList.remove('disabled');
      submitButton.classList.remove('disabled');
    } else {
      nextButton.classList.add('disabled');
      submitButton.classList.add('disabled');
    }

    input.addEventListener('input', (e) => {
      if (e.target.value) {
        if (e.target.id === 'email') {
          if (e.target.value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
            nextButton.classList.remove('disabled');
            submitButton.classList.remove('disabled');
          } else {
            nextButton.classList.add('disabled');
            submitButton.classList.add('disabled');
          }
        } else {
          nextButton.classList.remove('disabled');
          submitButton.classList.remove('disabled');
        }
      } else {
        nextButton.classList.add('disabled');
        submitButton.classList.add('disabled');
      }
    });
  }
}

const brandsMobSlider = document.querySelector('.brands-mob-slider');

if (brandsMobSlider) {
  const brandsMobSwiper = new Swiper(brandsMobSlider, {
    slidesPerView: 1,
    grabCursor: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    modules: [Autoplay],
  });
}

const projectImageSlider = document.querySelector('.project-image-slider');

if (projectImageSlider) {
  const slidesCount =
    projectImageSlider.querySelectorAll('.swiper-slide').length;

  const projectImageSwiper = new Swiper(projectImageSlider, {
    slidesPerView: 1,
    loop: slidesCount > 1,
    grabCursor: true,
    allowSlidePrev: false,
    // allowTouchMove: false,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    effect: 'creative',
    creativeEffect: {
      limitProgress: 2,
      progressMultiplier: 2,
      prev: {
        translate: [0, '-10%', -100],
        opacity: 0.75,
      },
      next: {
        translate: [0, '10%', 100],
        opacity: 0,
      },
    },
    // direction: 'vertical',
    modules: [Autoplay, EffectCreative],
  });

  projectImageSwiper.slideNext();
}
