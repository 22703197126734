// const loadScreen = document.querySelector('.load-screen');

// function shuffleArray(array) {
//   for (let i = array.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     [array[i], array[j]] = [array[j], array[i]];
//   }
//   return array;
// }

// if (loadScreen) {
//   const rectList = loadScreen.querySelectorAll('.load-screen__rect');

//   const shuffledArray = shuffleArray([...rectList]);

//   setTimeout(() => {
//     shuffledArray.forEach(function (item, index) {
//       setTimeout(function () {
//         item.classList.add('showed');
//       }, index * 2);
//     });

//     setTimeout(() => {
//       loadScreen.classList.add('loaded');

//       shuffledArray.forEach(function (item, index) {
//         setTimeout(function () {
//           item.classList.remove('showed');
//         }, index * 2);
//       });
//     }, shuffledArray.length * 2 + 250);
//   }, 300);
// }
