import Swiper from 'swiper';

window.addEventListener('click', ({ target }) => {
  const parrentWrap = target.closest('.group-card__wrap');
  const openedCardsList = document.querySelectorAll(
    '.group-card__popup.is-open'
  );

  if (!parrentWrap && openedCardsList.length) {
    openedCardsList.forEach((card) => {
      card.classList.remove('is-open');
    });
  }
});

const initGroupCard = () => {
  const groupCardList = document.querySelectorAll('.group-card__wrap');

  groupCardList.forEach((card, idx) => {
    const popup = card.querySelector('.group-card__popup');
    const cardList = popup.querySelectorAll('.work-card');
    const closeBtn = popup.querySelector('.popup__btn-close');

    popup.addEventListener('click', (e) => e.stopPropagation());
    closeBtn.addEventListener('click', () => popup.classList.remove('is-open'));
    cardList.forEach(
      (item, idx) => (item.style.transitionDelay = `${idx * 0.4 + 0.4}s`)
    );

    card.style.zIndex = groupCardList.length - idx;

    card.addEventListener('click', () => {
      groupCardList.forEach((card) => {
        const popup = card.querySelector('.group-card__popup');
        popup.classList.remove('is-open');
      });

      const popup = card.querySelector('.group-card__popup');
      popup.classList.toggle('is-open');
    });
  });

  const projectListSliders = document.querySelectorAll(
    '.group-card__project-list'
  );

  projectListSliders.forEach((slider) => {
    const projectListSwiper = new Swiper(slider, {
      slidesPerView: 1,
      grabCursor: true,
      spaceBetween: 20,
      rewind: true,
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        901: {
          slidesPerView: 3,
        },
      },
    });
  });
};

window.initGroupCard = initGroupCard;

initGroupCard();
